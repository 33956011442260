<style lang="scss" scoped>
@import "./new-client.modal";

.modal-mask {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.modal-content-container {
  background-color: white;
  min-width: 105vw;
  border-radius: 12px;
}

.lead-management__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}

.lead-management__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
}

.closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}



.lead-body-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 13vh;
}

.lead-management-modal-body-container {
  padding: 4vh 2vw;
  box-sizing: border-box;
}

.lead-body-row {
  display: flex;
  justify-content: space-between;
}

.lead-input-half {
  width: 47%;
}

.lead-input-container {
  margin-bottom: 2vh;
}

.lead-input-title {
  font-weight: 400;
  font-size: 1.5em;
  letter-spacing: 0.15px;
  color: #898989;
}

.lead-input-containear {
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1.5vh 0.7vw;
  display: flex;
}

.lead-input-value {
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 1.2em;
  letter-spacing: 0.15px;
  color: #B3B3B3;
  width: 90%;
}

.lead-input-value:focus {
  border: none;
  outline: none;
}
.cnpj-request-error-message{
    background-color: rgba(255, 0, 0,0.2);
    color: red;
    font-size: 1rem;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    -webkit-box-shadow: -1px 2px 5px 0px rgba(0,0,0,0.2);
-moz-box-shadow: -1px 2px 5px 0px rgba(0,0,0,0.2);
box-shadow: -1px 2px 5px 0px rgba(0,0,0,0.2);
margin-bottom: 5px;

}


@media only screen and (max-width: 500px) {
  .modal-content-container {
    width: 115vw;
  }
}
</style>
<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="lead-management__header-container">
        <span class="lead-management__header-title">
          Adicionar Lead
        </span>
        <span @click="closeDialog" class="material-icons closebtn-icon">
          cancel
        </span>
      </div>
      <div class="lead-management-modal-body-container">
        <div class="cnpj-request-error-message"  v-if="errorMessage">{{errorMessage}}</div>
        <div class="lead-input-container">
          <div class="lead-input-title"> CNPJ </div>
          <div class="lead-input-containear">
            <input v-mask="'## ### ###/####-##'"  v-on:change="getCnpjInfo()" type="text" v-model="cnpjInput"
              class="lead-input-value">
          </div>
        </div>

        <div class="lead-body-row lead-input-container">
          <div class="lead-input-half">
            <div class="lead-input-title"> Razao Social </div>
            <div class="lead-input-containear">
              <input type="text" v-model="nameInput" class="lead-input-value">
            </div>
          </div>

          <div class="lead-input-half">
            <div class="lead-input-title"> Email </div>
            <div class="lead-input-containear">
              <input type="text" v-model="emailInput" class="lead-input-value">
            </div>
          </div>


        </div>

        <div class="lead-body-row lead-input-container">


          <div class="lead-input-half">
            <div class="lead-input-title"> Contato </div>
            <div class="lead-input-containear">
              <input type="text" v-model="contactInput" class="lead-input-value">
            </div>
          </div>

          <div class="lead-input-half">
            <div class="lead-input-title"> Telefone </div>
            <div class="lead-input-containear">
              <input v-mask="'(##) ####-#####'" type="text" v-model="phoneInput" class="lead-input-value">
            </div>
          </div>



        </div>

        <div class="lead-body-row lead-input-container">


          <div class="lead-input-half">
            <div class="lead-input-title"> CEP </div>
            <div class="lead-input-containear">
              <input v-mask="'##.###-###'" type="text" v-model="cepInput" class="lead-input-value">
            </div>
          </div>


          <SelectCheckFiltered title="Tipo Revenda" :list="resales" class="lead-input-half" :alert="true"
            :action="value => resaleInput = value" />



        </div>
        <!-- <div class="lead-body-row lead-input-container">
          <div class="lead-input-half">
            <div class="lead-input-title"> Usuario </div>
            <div class="lead-input-containear">
              <input type="text" v-model="userInput" class="lead-input-value">
            </div>
          </div>
          <div class="lead-input-half">
            <div class="lead-input-title"> Senha </div>
            <div class="lead-input-containear">
              <input type="password" v-model="passwordInput" class="lead-input-value">
            </div>
          </div>
        </div> -->
        <div class="lead-body-button-container">
          <Button text="Salvar" v-model="nameInput" iconName="save" :action="() => { addLead() }" />
        </div>


      </div>
    </div>

    <cc-loader-full v-if="loading" />
  </div>
</template>

<script>

import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import Button from '@/components/ui/buttons/StandardButton.vue'
import BrasilApi from "../../../services/BrasilApi";
import StandardInput from "../../../components/ui/inputs/StandardInput.vue";
import ClientService from '@/services/v2/client.service.js'
import ClientServiceV3 from "@/services/v3/client.service";
import SelectCheckFiltered from '@/components/ui/inputs/SelectCheckFiltered.vue'

export default {
  mixins: [loaderMixin],
  props: {
    closeDialog: {
      type: Function,
      required: true
    },
    action: {
      type: Function
    }
  },
  components: {
    Button,
    StandardInput,
    SelectCheckFiltered
  },
  data() {
    return {
      brasilApi: new BrasilApi(),
      clientService: new ClientService(),
      clientServiceV3: new ClientServiceV3(),
      loading: false,
      isResaleUser: undefined,
      resales: [],
      nameInput: '',
      cnpjInput: '',
      emailInput: '',
      contactInput: '',
      phoneInput: '',
      cepInput: '',
      resaleInput: '',
      passwordInput: '123123',
      userInput: 'lead',
      errorMessage:''
    };
  },
  mounted() {
    this.isResaleUser = localStorage.getItem('revenda') > 1
    this.load_resales()
  },
  methods: {
    getCnpjInfo() {
      this.loading = true

      let cnpj = this.cnpjInput.replace(' ', '').replace(' ', '').replace('/', '').replace('-', '')
      this.brasilApi.getCnpjInfo(cnpj).then(resp => {
        this.nameInput = resp.data.razao_social
        this.cepInput = resp.data.cep
        this.contactInput = resp.data.qsa[0] ? resp.data.qsa[0].nome_socio : ''
        this.phoneInput = resp.data.ddd_telefone_1
        this.loading = false
        this.errorMessage=''

      }).catch((error) => {
       if(error.response.data && error.response.data.message){
        this.errorMessage=error.response.data.message
       }
        this.loading = false
      })
    },

    close() {
        this.errorMessage = ''
      this.$emit("close");
    },
    addLead() {
      this.loading = true
      this.clientService.addLead({
        cnpj: this.cnpjInput,
        email: this.emailInput,
        contato: this.contactInput,
        telefone: this.phoneInput,
        cep: this.cepInput,
        razao_social: this.nameInput,
        senha: this.passwordInput,
        usuario: this.userInput,
        revenda_id: this.resaleInput.id
      }).then(() => {
        this.loading = false
        this.closeDialog()
        this.action()
      }).catch(error => {
        if(error.response.data.mensagem){
					this.$swal.fire(
						error.response.data.mensagem,
						"Não foi possível cadastrar o lead.",
						"warning"
					)
				}
        this.loading = false
      })
    },
    load_resales() {
      return this.clientServiceV3.load_resales().then(response => response.data).then(data => {
        if (this.isResaleUser) {
          const resaleId = localStorage.getItem('revenda')
          data.data.forEach(element => {
            if (element.id == resaleId) {
              this.resales = [element]
            }
          });
        } else {
          this.resales = data.data
        }
        this.resales.forEach(element => {
          element.text = element.name
        });

      })
    },

  }
};
</script>
