import http from "../http";
import Service from "../../Service";

/**
 * @type RegistrationService
 */
export default class RegistrationService extends Service {

    list(params) {
		return http.get(`/admin/clients/registrations`, {
			headers: this.getHeaders(),
            params
		});
	}

	listLeads(params) {
		return http.get(`/admin/clients/registrations/club`, {
			headers: this.getHeaders(),
            params
		});
	}

    suspend(id) {
		return http.delete(`/admin/clients/registrations/${id}/suspend`, {
			headers: this.getHeaders()
		});
	}

	suspendLeads(id) {
		return http.delete(`/admin/clients/registrations/${id}/club/suspend`, {
			headers: this.getHeaders()
		});
	}

}
