<template>
    <!-- <div class="monitors">
      <div class="body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col col-2 pt-3">
                        <label for="" class="label-control">Selecione uma revenda</label>
                        <cc-select-v2
                            :tracker="'id'"
                            :text="'name'"
                            :searchable="true"
                            :options="resales"
                            v-model="filters.resale" />
                    </div>
                    <div class="col-9"></div>
                    <div class="col-1 p-4">
                        <cc-button
                            :classes="'fixed'"
                            :icon="'fas fa-chevron-left'"
                            :content="'Voltar'"
                            :link="'/clientes'" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-12 p-4 shadow rounded">
                        <cc-loader v-show="is_loading" />
                        <table class="table" :class="{ hidden : is_loading }">
                            <thead>
                                <tr>
                                    <th class="text-left pl-3">ID</th>
                                    <th>Responsável</th>
                                    <th>Email</th>
                                    <th>CNPJ</th>
                                    <th>Telefone</th>
                                    <th>Revenda</th>
                                    <th class="text-left">Status</th>
                                    <th class="text-left">Enviado em</th>
                                    <th class="text-left"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="registration in registrations" v-bind:key="'head-office-' + registration.id" :class="{
                                    waiting: !registration.imported,
                                    confirmed: registration.imported,
                                }">
                                    <td class="text-left">
                                        <strong>#{{ registration.id }}</strong>
                                    </td>
                                    <td class="text-uppercase">{{ registration.name }}</td>
                                    <td>{{ registration.email }}</td>
                                    <td class="text-uppercase">{{ registration.cnpj }}</td>
                                    <td>
                                        <a class="zap" :href="'https://api.whatsapp.com/send?phone=' + formatNumber(registration.phone)" target="_blank">
                                            <i class="fab fa-whatsapp"></i>
                                            {{ registration.phone || "" | VMask('(##) ####-#####') }}
                                        </a>
                                    </td>
                                    <td>{{ registration.resale ? registration.resale.name : '' }}</td>
                                    <td>
                                        <i class="far fa-envelope pr-2"></i>
                                        {{ registration.imported ? 'Importado' : 'Em aberto'  }}
                                    </td>
                                    <td>{{ registration.created_at | formatDate }}</td>
                                    <td class="actions">
                                        <i class="fas fa-user-plus" title="Efetivar Registro" @click="open_client_modal(registration)" />
                                        <i class="fas fa-file-export" title="Arquivar Registro" @click="() => suspend(registration)" ></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer clearfix not-print">
                    <cc-paginacao :key="'pag'"
                        classes="orange"
                        :items_by_page_value="items_by_page_value"
                        :total="total_items"
                        :page="page"
                        :page_limit="11"
                        v-on:change="load($event)"></cc-paginacao>
                </div>
            </div>
        </div>
        
    </div> -->
    <div class="registration-page">
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
            Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <div class="page-route-select-text">
                Solicitações Lead
            </div>
        </div>
        <div class="page-title">Solicitações Lead</div>
        <div class="registration-page-header">
            <div class="registration-page-header-right">
                <StandardInput class="registration-page-header-mobile" :title="'Selecione uma revenda'" :list="resales" :auto="true"  :action="setFilter" :type="'select'"/>
                <StandardInput class="registration-page-header-mobile registration-page-header-cnpj" :title="'Filtrar CNPJ'"  :action="setCnpj" mask="## ### ###/####-##" :type="'text'"/>
            </div>
            

            
            <div class="registration-page-header-right">
                <StandardButton class="registration-page-header-mobile" :action="close" :iconName="'arrow_back'" :text="'Voltar'"/>
                <StandardButton class="registration-page-header-mobile" :iconName="'person_add'" title="Adicionar Lead" :text="'Adicionar Lead'" :action="() => { open_lead_modal() }" />
            </div>
        </div>
        <div class="page-table-header">
            <div class="page-table-header-text id-width">ID</div>
            <div class="page-table-header-text resp-width">RESPONSÁVEL</div>
            <div class="page-table-header-text email-width">EMAIL/TELEFONE</div>
            <div class="page-table-header-text document-width">CNPJ/RAZÃO SOCIAL</div>
            <div class="page-table-header-text resale-width">REVENDA</div>
            <div class="page-table-header-text status-width">STATUS</div>
            <div class="page-table-header-text date-width">ENVIADO EM</div>
            <div class="action-width"></div>
        </div>
        <div class="page-table-line" :class="getSpecialBackground(index)" v-for="(registration, index) in registrations" v-bind:key="index">
            <div class="page-table-row id-width"> <div class="page-table-mobile-header">ID</div> #{{ registration.id }}</div>
            <div class="page-table-row resp-width"><div class="page-table-mobile-header">RESPONSÁVEL</div>{{ registration.name }}</div>
            <div class="page-table-row email-width">
                <div class="page-table-mobile-header">EMAIL</div>
                <div>
                    {{ registration.email }} <br>
                    <a class="zap" :href="'https://api.whatsapp.com/send?phone=' + formatNumber(registration.phone)" target="_blank">
                                            <i class="fab fa-whatsapp"></i>
                                            {{ registration.phone || "" | VMask('(##) ####-#####') }}
                                        </a>
                </div>
            </div>
            <div class="page-table-row document-width">
                <div class="page-table-mobile-header">CNPJ</div>
                <div>
                    {{ registration.cnpj }} <br>
                    {{ registration.razaoSocial }}
                </div>
            </div>
            <div class="page-table-row resale-width"><div class="page-table-mobile-header">REVENDA</div>{{ registration.resale ? registration.resale.name : '' }}</div>
            <div class="page-table-row status-width"><div class="page-table-mobile-header">STATUS</div>{{ registration.imported ? 'Importado' : 'Em aberto'  }}</div>
            <div class="page-table-row date-width"><div class="page-table-mobile-header">ENVIADO EM</div>{{ registration.created_at | formatDate }}</div>
            <div class="action-width">
                <div class="page-table-mobile-header"></div>
                <div>
                    <span class="material-icons-outlined page-title-icon-add" title="Efetivar Registro" @click="open_client_modal(registration)">group_add</span>
                    <span class="material-icons-outlined page-title-icon-arq" title="Arquivar Registro" @click="() => suspend(registration)">drive_file_move</span>
                </div>
                
            </div>
        </div>

        <cc-loader-full v-if="is_loading"/>
        <addLeadModal  :closeDialog="closeLeadModal" :action="load"
                v-if="showLeadModal" />
        <cc-new-client-modal
            :registration="current_registration"
            :closeDialog="close_modal"
            @reload="load"
            v-if="is_client_modal_open"
        />
    </div>
</template>
<script>

import Paginacao from "@/components/cliente/base-components/Pagination";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import RegistrationService from "@/services/v3/clients/registration.service";
import NewClientModal from "@/modals/client/new/new-client.modal";
import ClientService from "@/services/v3/client.service";
import { perm_mixin } from '@/mixins/permission.mixin'
import StandardInput from '@/components/ui/inputs/StandardInputV2.vue'
import StandardButton from '@/components/ui/buttons/StandardButton.vue'
import addLeadModal from "@/modals/client/new/addLeadModal.vue";

export default {
    mixins: [ loaderMixin, perm_mixin ],
    components: {
        ccPaginacao: Paginacao,
        'cc-new-client-modal' : NewClientModal,
        StandardInput,
        StandardButton,
        addLeadModal
    },
    data(){
        return{
            is_loading: false,
            total_items: 1,
            page: 1,
            filters: {},
            statuses: [
                {
                    name: 'Aguardando Confirmação',
                    code: 'WAITING_CONFIRMATION'
                },
                {
                    name: 'Confirmado',
                    code: 'CONFIRMED'
                },
                {
                    name: 'Cancelado',
                    code: 'CANCELED'
                }
            ],
            is_client_modal_open: false,
            items_by_page_value: 100,
            svc: new RegistrationService(),
            cli_svc: new ClientService(),
            registrations: [],
            resales: [{text: 'Todos', value: undefined}],
            current_registration: null,
            isMainOnly: true,
            showLeadModal: false,
            breadcrumbs: [
                {
                    name: "Clientes"
                },
                {
                    name: "Requisição de Registros",
                    route: 'clients-registration-requests'
                }
            ]
        }
    },
    methods: {
        open_lead_modal() {
            this.showLeadModal = true
        },
        closeLeadModal: function() {
          this.showLeadModal = false;
        },
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        close () { this.$router.push('/clientes/') },
        setFilter (value) { 
            this.filters.resale = value
            this.load()
        },
        formatNumber(number){
            if (!number) return null
            number = number.replace('+','').replace('(','').replace(')','').replace('-','')
            if (number[0] + '' + number[1] != '55' ) number = '55' + number
            return number
        },
        setCnpj (value) {
            this.filters.cnpj = value
            this.load(1)
        },
        load_resales() {
            this.cli_svc.load_resales().then(response => response.data).then(data => {
                if (this.isMainOnly) {
                    const resaleId = localStorage.getItem('revenda')
                    data.data.forEach(element => {
                        if ( element.id == resaleId) {
                            this.resales.push({value: element, text: element.name})
                            this.filters.resale = element
                        }
                    })
                } else {
                    data.data.forEach(element => this.resales.push({value: element, text: element.name}))
                }
                this.load()
            })
		},
        close_modal(reload = false) {
            this.is_client_modal_open = false
            if(reload) {
                this.load()
            }
        },
        open_client_modal(registration) {
            this.current_registration = registration
            this.is_client_modal_open = true
        },
        suspend(registration) {
            this.confirm_action({
				message: "Este registro será arquivado, confirma?",
                subtitle: `#${registration.name.toUpperCase()}`,
				callback: () => {
                    this.present_loader("Arquivando registro...")
                    this.svc.suspendLeads(registration.id).then(() => {
                        this.present_info("Registro arquivado!")
                        this.load()
                    })
				}
			})
        },
        load(new_page = this.page) {
            this.page = new_page;
            this.is_loading = true;
            let params = {
                resale_id: this.filters.resale ? this.filters.resale.id : null,
                cnpj: this.filters.cnpj ? this.filters.cnpj : null
            }
            this.svc.listLeads(params)
            .then(response => response.data).then(data => {
                this.registrations = data.data;
                this.total_items = data.meta.total;
                this.is_loading = false;
            }).catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        }
    },
    created() {
        this.perms.forEach(element => { if (element.action == 'READ' && element.resource == 'MAIN_TOP') this.isMainOnly = false });
        this.load_resales()
        this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_tables';

    .canceled {
        i {
            color: $brand-danger;
        }
    }
    .waiting {
        i.fa-envelope {
            color: $yellow2;
        }
    }
    .confirmed {
        i.fa-envelope {
            color: $ternary-color;
        }
    }

    .zap {
        i {
            color:#4AC959;
        }
        font-size: 1em;
        width: fit-content;
        &:hover {
            background: #4AC959;
            color: white;
            border-radius: 5px;
            i {
                color: white;
            }
        }
    }

    .actions {
        justify-content: center;
        i {
            margin-left: 10px;
            font-size: 1.2rem;
            cursor: pointer;
            &.fa-trash {
                color: red;
            }
        }
    }

.monitors {
  font-size: 1vw;
  margin-right: 4em;
}
.registration-page {
    font-size: 1vw;
}
.page-route-select {
  display: flex;
  align-items: center;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color:  var(--primary-color);
  margin-right: 20px;
}
.registration-page-header-cnpj{
    width: 20vw;
}
.page-title{
  margin-top: 45px;
  font-weight: 700;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
}
.registration-page-header{
    display: flex;
    justify-content: space-between;
    margin-top: 3vh;
    margin-right: 5vw;
    align-items: flex-end;
}
.registration-page-header-right{
    display: flex;
    gap: 4vw;
    align-items: flex-end;
}
.page-table-mobile-header{
    display: none;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
  margin-top: 5vh;
  margin-right: 5vw;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.id-width {  width: 5%; }
.resp-width {  width: 16%; }
.email-width {  flex: 1; }
.document-width {  width: 20%; }
.phone-width {  width: 13%; }
.resale-width {  width: 19%; }
.status-width {  width: 7%; }
.date-width {  width: 10%; }
.action-width {  width: 6%; }
.page-table-line-special { background: #F7F7F7;}
.page-table-line{
    display: flex;
    margin-right: 5vw;
    align-items: center;
}
.page-table-row {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 1em;
  color: #605F5F;
  padding: 2vh 0 2vh 10px;
}
.page-title-icon-add{
    cursor: pointer;
    margin-right: 10%;
    color: #20CE88
}
.page-title-icon-arq{
    cursor: pointer;
    color: #4267B2;
}

@media only screen and (max-width: 1000px) {
    .registration-page {
        font-size: 2vw;
    }
    .page-table-header{
        display: none;
    }
    .page-table-line{flex-direction: column; font-size: 2em;}
    .page-table-mobile-header{display: flex;}
    .page-table-row{justify-content: space-between; text-align: end;}
    .id-width {  width: 100%; }
    .resp-width {  width: 100%; }
    .email-width {  flex: unset; width: 100%; }
    .document-width {  width: 100%; }
    .phone-width {  width: 100%; }
    .resale-width {  width: 100%; }
    .status-width {  width: 100%; }
    .date-width {  width: 100%; }
    .action-width {  width: 100%; display: flex; justify-content: space-between;}
}
@media only screen and (max-width: 500px) {
    .registration-page {
        font-size: 2.5vw;
    }
    .registration-page-header{ flex-direction: column; gap: 2vh;}
    .registration-page-header-mobile{width: 100%;}
}
</style>
